import { Component, ComponentFactory, ComponentFactoryResolver, ComponentRef, Injector, OnInit, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { ChartConfiguration, ChartData, ChartEvent, ChartType } from 'chart.js';
import { BaseChartDirective } from 'ng2-charts';
import DataLabelsPlugin from 'chartjs-plugin-datalabels';
import { DataTableDirective } from 'angular-datatables';
import { ToastrService } from 'ngx-toastr';
import { AppService } from '@services/app.service';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ButtonComponent } from '@components/button/button.component';
import { Observable, Observer, Subject } from 'rxjs';
import { ADTSettings } from 'angular-datatables/src/models/settings';
import { ColumnConfig, TableComponent } from '@components/table/table.component';

import { Store } from '@ngrx/store';
import { StateState } from '@/store/state/state';
import { AppState } from '@/store/state';
import { ActivatedRoute, Router } from '@angular/router';
import * as dayjs from 'dayjs';
import { SetUiMode, UpdateSelectedNewsId, UpdateSelectedPlanId } from '@/store/state/actions';
import { iOS } from '@/utils/themes';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-plan-content',
  templateUrl: './plan-content.component.html',
  styleUrls: ['./plan-content.component.scss']
})
export class PlanContentComponent implements OnInit {
  private state$: Observable<any>;
  planId: Number = null;
  planObj;
  relatedSales;
  relatedPlans: any[];

  type = "";
  typeText = "";

  shareTitle = "";
  shareDescription = "";
  salesicon = null;

  modal: NgbModalRef;
  @ViewChild('shareModal') public shareModal: TemplateRef<any>;

  bottomBarBottom = '0px';

  constructor(
      private toastr: ToastrService,
      private appService: AppService,
      private modalService: NgbModal,
      public viewContainerRef: ViewContainerRef,
      private injector: Injector,
      private store: Store<AppState>,
      private router: Router,
      private route: ActivatedRoute,
      private metaTagService: Meta,
      private titleService: Title
  ) {
    this.type = this.route.snapshot.paramMap.get('type');
    let newsId = this.route.snapshot.paramMap.get('id');
    console.log('exe1');
    if(!newsId) {
      console.log('exe1/5');
      console.log(newsId);
      switch(this.type) {
        case "fixedwired":
          this.titleService.setTitle('電訊巴打-寛頻報價助您選擇合適的香港寬頻上網計劃，全港最齊寬頻比較平台');
          this.typeText = "固網寛頻";
          this.metaTagService.updateTag({name: 'og:type', content: 'article'});
          this.metaTagService.updateTag({name: 'og:title', content: '電訊巴打-寛頻報價助您選擇合適的香港寬頻上網計劃，全港最齊寬頻比較平台'});
          this.metaTagService.updateTag({name: 'og:description', content: '當今在香港，選擇合適的家居寬頻對於工作、學習和娛樂都十分重要。本文將深入探討如何透過電訊巴打「寬頻比較」來選擇最適合的「家居寬頻plan」，並強調「寬頻報價」的重要性。以下是詳細分析，幫助你了解如何選擇最佳的「寬頻plan」。'});
          this.metaTagService.updateTag({name: 'description', content: '當今在香港，選擇合適的家居寬頻對於工作、學習和娛樂都十分重要。本文將深入探討如何透過電訊巴打「寬頻比較」來選擇最適合的「家居寬頻plan」，並強調「寬頻報價」的重要性。以下是詳細分析，幫助你了解如何選擇最佳的「寬頻plan」。'});
          break;
        case "fixedcellular":
          this.titleService.setTitle('5G家居寬頻比較- 3HK 5G寬頻｜SMARTONE 5G寬頻｜CSL 5G寬頻 電訊巴打助你作高質實惠之選');
          this.typeText = "5G 寬頻";
          this.metaTagService.updateTag({name: 'og:type', content: 'article'});
          this.metaTagService.updateTag({name: 'og:title', content: '5G家居寬頻比較- 3HK 5G寬頻｜SMARTONE 5G寬頻｜CSL 5G寬頻 電訊巴打助你作高質實惠之選'});
          this.metaTagService.updateTag({name: 'og:description', content: '電訊巴打有住全港多間電訊公司銷售員，助理比較自選出合適5G寬頻月費計劃、轉台計劃。加上我們所提供的資料既新且準，要找最平5G寬頻比較，甚至5G寬頻網絡測試或5G寬頻優惠十分容易。'});
          this.metaTagService.updateTag({name: 'description', content: '電訊巴打有住全港多間電訊公司銷售員，助理比較自選出合適5G寬頻月費計劃、轉台計劃。加上我們所提供的資料既新且準，要找最平5G寬頻比較，甚至5G寬頻網絡測試或5G寬頻優惠十分容易。'});
          break;
        case "mobile":
          this.titleService.setTitle('最平電話月費-5G Plan比較、中國移動月費計劃、大灣區數據比較、最平一卡兩號比較及手機攜號轉台比較 | 手機上網plan比較平台');
          this.typeText = "手機數據";
          this.metaTagService.updateTag({name: 'og:type', content: 'article'});
          this.metaTagService.updateTag({name: 'og:title', content: '最平電話月費-5G Plan比較、中國移動月費計劃、大灣區數據比較、最平一卡兩號比較及手機攜號轉台比較 | 手機上網plan比較平台'});
          this.metaTagService.updateTag({name: 'og:description', content: '電訊巴打有著全港多間電訊公司銷售員，助你比較至選出合適上台、轉台計劃。加上我們所提供的資料既新且準，要找最平5G plan，甚至4.5G plan手機上台月費及手機上台優惠十分容易！'});
          this.metaTagService.updateTag({name: 'description', content: '電訊巴打有著全港多間電訊公司銷售員，助你比較至選出合適上台、轉台計劃。加上我們所提供的資料既新且準，要找最平5G plan，甚至4.5G plan手機上台月費及手機上台優惠十分容易！'});
          break;
        case "tv":
          this.titleService.setTitle('收費電視 | 電訊巴打-電訊優惠比較平台-家居寬頻、商業寬頻、流動通訊、收費電視 - 電訊巴打');
          this.typeText = "收費電視";
          break;
      }
    } else {
      if(this.type == 'mobile' && (newsId == '227' || newsId == '356' || newsId == '284' || newsId == '168')) {
        this.metaTagService.addTag({name: 'googlebot', content: 'noindex'});
      }
      if(this.type == 'fixedwired' && (newsId == '26' || newsId == '538')) {
        this.metaTagService.addTag({name: 'googlebot', content: 'noindex'});
      }
      if(this.type == 'fixedcellular' && (newsId == '517' || newsId == '352')) {
        this.metaTagService.addTag({name: 'googlebot', content: 'noindex'});
      }
    }
    // console.log(this.type);

  }

  ngOnInit(): void {
    this.route.paramMap.subscribe(params => {
      this.type = params.get('type');
    });

    this.type = this.route.snapshot.paramMap.get('type');
    // console.log(this.type);
    var id = this.route.snapshot.paramMap.get('id');
    // console.log(id);
    if(id) {
      this.planId = parseInt(id);
      this.loadNews();
      this.loadRelatedPlans();
    } else {
      this.planId = null;
    }
    this.store.dispatch(new UpdateSelectedPlanId(this.planId));
    this.store.select('state').subscribe((n) => {
      this.planId = n.planId;
      if(n != null && n.planId != null) {
        this.loadNews();
        this.loadRelatedPlans();
      } else {
        this.planObj = undefined;
        this.relatedSales = undefined;
      }

      if(n.config) {
        this.salesicon = n.config.salesicon;
      }
    })
  }

  async ngAfterViewInit() {
    await new Promise(resolve => { setTimeout(() => { resolve(0); }, 100); });
    if(iOS()) {
      // console.log('ios');
      this.bottomBarBottom = '60px';
    }
  }

  loadNews() {
    this.appService.dataGet("plans", {
      key: "id",
      value: this.planId.toString()
    }).then((result) => {
      if(result.payload.results.length > 0) {
        this.planObj = result.payload.results.pop();
        if(this.planObj.related && this.planObj.related.sales && this.planObj.related.sales.length > 0 && this.planObj.related.sales[0] != null) {
          this.relatedSales = this.planObj.related.sales[0];
        }
        this.shareTitle = this.planObj.title;
        var waNum = "";
        if(this.relatedSales != null && this.relatedSales.whatsapp != null) {
          // console.log(this.planObj.related.sales[0].whatsapp);
          waNum = this.relatedSales.whatsapp.replace(/ /g, '').replace(/\+/g, '').replace(/\-/g, '');
          if (waNum.length == 8) {
            waNum = "852" + waNum;
          }
        }
        this.shareDescription = "⭐" +
          (this.planObj.title ?? "") +
          "⭐\n" +
          "- " +
          (this.planObj.tagline1 ?? "") +
          "\n" +
          "- " +
          (this.planObj.tagline2 ?? "") +
          "\n" +
          "- " +
          (this.planObj.tagline3 ?? "") +
          "\n" +
          "\n" +
          (this.relatedSales != null ? ("馬上聯絡 Sales " + this.relatedSales.name + "\n") : "") +
          (this.relatedSales != null && this.relatedSales.mobile != null ? ("📞: " + this.relatedSales.mobile + "\n") : "") +
          (this.relatedSales != null && this.relatedSales.whatsapp != null ? ("Whatsapp: " + 'https://wa.me/' + waNum + "\n") : "") +
          (this.relatedSales != null && this.relatedSales.telegram != null
              ? ("TG: " + 'https://t.me/' + this.relatedSales.telegram + "\n")
              : "");

        // this.metaTagService.addTags([
        //   {name: 'og:type', content: 'article'},
        //   {name: 'og:title', content: this.shareTitle + ' - 電訊巴打'},
        //   {name: 'og:description', content: this.shareDescription},
        //   {name: 'og:image', content: this.planObj.thumbnail},
        // ]);
    console.log('exe2');
        this.metaTagService.removeTag('name="og:type"');
        this.metaTagService.removeTag('name="og:title"');
        this.metaTagService.removeTag('name="og:description"');
        this.metaTagService.removeTag('name="description"');
        this.metaTagService.removeTag('name="og:image"');
        this.metaTagService.updateTag({name: 'og:type', content: 'article'});
        this.metaTagService.updateTag({name: 'og:title', content: this.shareTitle + ' - 電訊巴打'});
        this.metaTagService.updateTag({name: 'og:description', content: this.shareDescription});
        this.metaTagService.updateTag({name: 'description', content: this.shareDescription});
        this.metaTagService.updateTag({name: 'og:image', content: this.planObj.thumbnail});
        this.titleService.setTitle(this.shareTitle + ' - 電訊巴打');
      }
    })
  }

  ngOnDestroy(): void {
  }

  onWhatsapp(): void {
    var waNum = this.planObj.related.sales[0].whatsapp.toString();
    waNum = waNum.replace(' ', '').replace('+', '').replace('-', '');
    if (waNum.length == 8) {
      waNum = "852" + waNum;
    }
    window.open('https://wa.me/' + waNum + "?text=我喺「電訊巴打」✊🏻見到你嘅優惠「" + (this.planObj.title ?? "") + "」,想查詢一下優惠詳情！（請勿刪除）")
  }

  onPhone(): void {
    window.open('tel:' + this.planObj.related.sales[0].mobile);
  }

  onTelegram(): void {
    window.open('https://t.me/' + this.planObj.related.sales[0].telegram);
  }

  onShare(): void {
    this.modal = this.modalService.open(this.shareModal, { size: 'md' });
  }

  isShowLimitedTime(): boolean {
    if(this.planObj.show_limited_time) {
      var startDate = dayjs(this.planObj.limited_time_start).startOf('day');
      var endDate = dayjs(this.planObj.limited_time_end).endOf('day');
      if(startDate <= dayjs() && endDate >= dayjs()) {
        return true;
      }
    }
    return false;
  }

  dateFormat(dateStr): string {
    return dayjs(dateStr).format('YYYY-MM-DD');
  }

  onClose(): void {
    this.router.navigateByUrl('/plans/' + this.type);
    this.store.dispatch(new UpdateSelectedPlanId(null));
    this.store.dispatch(new SetUiMode('listview'));
  }

  loadRelatedPlans() {
    this.appService.dataGet("plans", {
      key: "id",
      value: this.planId.toString()
    }).then((result) => {
      if(result.payload.results.length > 0) {
        this.planObj = result.payload.results.pop();
        if(this.planObj.related && this.planObj.related.sales && this.planObj.related.sales.length > 0 && this.planObj.related.sales[0] != null) {
          this.relatedSales = this.planObj.related.sales[0];
        }
        if(this.relatedSales != null && this.relatedSales.id != null) {
          this.appService.dataGet("plans", null, [{
              key: 'TeleBro_sales_id',
              value: [this.relatedSales.id]
            }],
            {
              key: 'id',
              value: this.planObj.id
            }, 'rand()'
          ).then((result2) => {
            this.relatedPlans = result2.payload.results;
          });
        }
      }
    })
  }

  onClickRelatedPlan(plan) {
    this.store.dispatch(new UpdateSelectedPlanId(plan.id));
    this.router.navigate(['plans', plan.type, plan.id]);
  }
}
