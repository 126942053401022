<div class="main-wrapper">
  <div class="text-left">
    <h4 class="mt-2">隱私政策</h4>
  <p>個人資料（私隱）條例</p>
  <p>根據個人資料（私隱）條例（條例），本公司收集、使用和保密您的個人資料，並確保其完整性。</p>
  <p>個人資料</p>

  <p>本公司所持有的客戶及銷售人員的個人資料包括但不限於姓名、電話號碼、電郵地址、地址、銷售人員所在公司名稱及在職證明，或本公司要求提供的其他資料 服務（統稱為“個人數據”）。</p>

    <p>目的</p>

      <p>個人資料可用於以下目的：<br />
1. 提供、維護和改進公司網站和移動應用程序的日常服務；<br />
2. 向客戶發送通知或以其他方式聯繫客戶以獲取電信服務或產品的更新和特別優惠；<br />
3. 為方便客戶向業務員查詢，直接向客戶提供業務員的姓名、聯繫方式或其他個人資料（如有必要）；<br />
4. 處理用戶的投訴和查詢；<br />
5. 進行數據分析和質量評估；<br />
6. 按照適用於公司的法律要求進行披露； 或者<br />
7. 做任何與上述直接相關的事情。</p>

<p>客戶或銷售人員未能按公司要求提供任何正確或充分的個人資料可能導致公司無法向客戶或銷售人員提供服務。</p>

<p>個人資料的轉移</p>

<p>本公司將對個人資料保密，但此類個人資料有可能提供給以下各方：</p>

<p>1. 公司的子公司或關聯公司；<br />
2. 本公司的第三方服務供應商；<br />
3. 任何公共媒體（僅用於處理公共媒體提及的客戶投訴和查詢）；<br />
4. 公司根據任何適用法律有義務向其披露的任何人；<br />
5. 對本公司負有保密義務的其他人員。</p>

<p>訪問和修改個人數據</p>

<p>您有權訪問和修改公司擁有的與您有關的任何個人數據。 任何訪問和修改個人數據的請求均應以書面形式提出並通過電子郵件發送至公司。</p>

<p>根據條例，我們有權就處理任何查閱個人資料的要求收取合理的費用。</p>

<p>個人資料的保留</p>

<p>公司將在合理必要的時間內保留個人數據，以實現收集和使用個人數據的目的。</p>

<p>變化</p>

<p>本公司可能會不時修改本隱私政策。 任何此類修改應在公司網站或 「電訊巴打」 上發布後立即生效。</p>

<h4 class="mt-4">會員（客戶）使用條款</h4>
<p>本公司是一款名為「電訊巴打」的移動應用程序，由「電訊巴打」公司提供。 在成為會員並使用「電訊巴打」之前，您必須仔細閱讀並同意以下使用條款。
註冊為用戶和或使用 「電訊巴打」，即表示您同意遵守這些條款並受其約束。</p>

<p>A. 服務</p>

<p>1. 「電訊巴打」的目的是作為一個技術平台，使用戶能夠查詢有關電訊服務或產品的信息，並進一步與提供這些服務或產品的獨立第三方提供商進行溝通。<br />
本公司不會向用戶提供任何電信服務或產品，並且所有這些電信服務或產品均由獨立的第三方提供商提供。</p>

<p>2. 用戶在與第三方提供商溝通或使用他們的服務或產品的過程中，也可能受第三方提供商的使用條款和隱私政策的約束。 本公司不代表「電訊巴打」中包含的任何第三方服務或內容，並且在任何情況下，本公司均不作為任何第三方提供者的代理人，也不對任何服務或產品承擔任何責任或義務。</p>

<p>3. 本公司和「電訊巴打」的任何部分或內容僅供您個人使用或訪問。 用戶須同意不 (i) 重新製作、複製、修改、製作衍生作品、分發、出租、出售、轉售或以其他方式用於商業目的； (ii) 反向工程或拆除服務； (iii) 鏡像或偽造服務的任何部分； (iv) 在任何方面嚴重阻礙服務的運行，或試圖未經授權訪問或損害服務或其相關係統或網絡的任何方面。</p>

<p>4. 用戶同意在使用「電訊巴打」時遵守所有適用的法律，並僅將本服務用於合法目的。</p>

<p>5. 用戶理解並同意本服務可能包含提供本服務所必需的廣告。</p>

<p>6. 本公司保留因維修、保養、升級或任何其他其認為適當的工作而暫停全部或部分服務的權利，恕不另行通知用戶。</p>

<p>B. 內容和產品</p>

<p>1. 「電訊巴打」中包含的服務計劃、信息中心和其他第三方服務中發布的某些信息以及來自第三方提供商網站、手冊或註冊熱線的所有內容僅供參考。 因此，本公司不保證其準確性、完整性和有效性。 所有信息均以第三方供應商官方網站為準，其決定為最終決定。</p>

<p>2、用戶可以向註冊銷售員索取“報價”，但本公司不能保證銷售員向您提供的報價的準確性、完整性和有效性。 對於任何第三方提供給您的報價單中包含的任何不正確、虛假、過時、不完整或具有誤導性的內容或信息，本公司不承擔任何責任或以任何形式提出索賠。</p>

<p>3. 服務按“原樣”提供。 對於任何用戶通信或自定義設置的限制期限、刪除、傳輸錯誤或存儲失敗，本公司不承擔任何責任。</p>

<p>4. 本公司不對條款中未明確規定的明示、默示或法定的陳述和保證，包括對適銷性、特定用途的適用性和不侵權的任何默示保證。<br />
此外，本公司對使用「電訊巴打」獲得的任何服務或產品的可靠性、及時性、質量、適用性或可用性，或本公司將不間斷地運行不作任何陳述、保證或保證 或故障。 本公司不對第三方供應商提供的服務的質量、適用性、安全性或性能作出任何保證。</p>

<p>C. 免責和賠償</p>

<p>1. 對於因以下原因引起的任何損害、責任或損失，本公司不承擔任何形式的責任或賠償： (i) 您使用或依賴本服務，或您未能訪問或使用本服務； (ii) 「電訊巴打」中包含的內容或任何人通過 「電訊巴打」作為平台提供的信息和內容； 或 (iii) 您與任何第三方提供商之間的通信、交易或關係，儘管公司已被告知此類損害的可能性</p>

<p>2. 本公司對任何延遲或未能提供任何服務或未能更新任何內容不承擔任何責任。</p>

<p>3. 本公司不對任何與使用本產品有關、相關或引起的任何間接、附帶、特殊、懲戒性、懲罰性或後果性損害賠償，包括利潤或信息損失、人身傷害或財產損失承擔責任。 服務，儘管公司已被告知此類損害的可能性。</p>

<p>4. 您同意賠償本公司及其高級管理人員、董事、工作人員和代理人因以下任何及所有原因引起的任何索賠、要求、損失、責任和費用（包括律師費）：（ i) 您使用「電訊巴打」獲得服務或產品； (ii) 您違反任何法律或違反任何條款； (iii) 公司對您提供的任何信息的使用； 或 (iv) 您違反任何第三方（包括第三方提供）的權利。</p>

<p>D. 條款修訂</p>

<p>本公司有權在不通知用戶的情況下不時修改本條款。 此類修改後的條款應在發布後立即生效，並取代所有以前的版本。 您在發布修改後的條款後繼續訪問或使用「電訊巴打」服務即表示您同意受其約束。 「電訊巴打」的“使用條款”中提供了最新版本的條款。</p>

<p>E. 隱私政策</p>

<p>本公司可能會通過「電訊巴打」向客戶發送通知或以其他方式聯繫客戶以獲取電信服務或產品的更新和特別優惠。 有關公司使用客戶個人數據的詳細信息，請閱讀「電訊巴打」中的隱私政策。</p>

<p>F. 無效</p>

<p>如果在任何時候，本條款的任何規定全部或部分被認定為非法、無效或不可執行，則該等非法、無效或不可執行的規定應被視為已從本條款中刪除，但本條款的所有其他規定 條款應保持完全有效，不得因此受到影響或損害。</p>

<p>G. 適用法律和管轄權</p>

<p>本條款受香港法律管轄並按其解釋。</p>
</div>
</div>
